<template>
  <div class="site__centered application-steps">

<!--    <div-->
<!--      class="row"-->
<!--    >-->
<!--&lt;!&ndash;      <div&ndash;&gt;-->
<!--&lt;!&ndash;        v-if="familySettings.use_family_form"&ndash;&gt;-->
<!--&lt;!&ndash;        class="btn application-steps__header test"&ndash;&gt;-->
<!--&lt;!&ndash;        @click="toggleCollapseFamilyFormsTabs"&ndash;&gt;-->
<!--&lt;!&ndash;      >Family Form</div>&ndash;&gt;-->

<!--      <div-->
<!--        v-if="familySettings.use_family_form && !isFamilyFormCollapsed"-->
<!--        class="tab-content col application-steps__tab-content"-->
<!--      >-->
<!--        <FamilyForm/>-->
<!--      </div>-->
<!--    </div>-->



<!--    <b-tabs-->
<!--      v-model="tabIndex"-->
<!--      vertical-->
<!--      pills-->
<!--      content-class="application-steps__tab-content"-->
<!--      :nav-wrapper-class="isFamilyFormCollapsed ? 'application-steps__tabs is-tabs-collapsed' : 'application-steps__tabs'"-->
<!--      nav-class="nav-left"-->
<!--      active-nav-item-class="application-steps__tab_active"-->
<!--    >-->
<!--      <template #tabs-start>-->
<!--        <li-->
<!--          class="application-steps__header"-->
<!--          @click="toggleCollapseFamilyFormsTabs"-->
<!--        >-->
<!--          Family Form-->
<!--          <span-->
<!--            class="collapse-icon"-->
<!--            :class="{'open': isFamilyFormCollapsed}"-->
<!--          ></span>-->
<!--        </li>-->
<!--      </template>-->
<!--      <b-tab-->
<!--        title-item-class="application-steps__tab"-->
<!--        title-link-class="application-steps__tab-link"-->
<!--        v-if="!isFamilyFormCollapsed"-->
<!--      >-->
<!--        <template #title>-->
<!--          <div>-->
<!--            <span class="font-weight-bold text-primary"><b>Family Form</b></span>-->
<!--          </div>-->

<!--        </template>-->
<!--        <FamilyForm/>-->
<!--      </b-tab>-->
<!--    </b-tabs>-->

    <b-tabs
      v-model="tabIndex"
      vertical
      pills
      content-class="application-steps__tab-content"
      :nav-wrapper-class="isCollapsed ? 'application-steps__tabs is-tabs-collapsed' : 'application-steps__tabs'"
      nav-class="nav-left"
      active-nav-item-class="application-steps__tab_active"
    >
      <template #tabs-start>
        <li
          class="application-steps__header"
          @click="toggleCollapseTabs"
        >
          Steps Application Form
          <span 
            class="collapse-icon"
            :class="{'open': isCollapsed}"
          ></span>
        </li>
      </template>
      <b-tab
        v-if="getIsUseFamilyForm"
        title-item-class="application-steps__tab"
        title-link-class="application-steps__tab-link"
        @click="setTabIndexQuery"
      >
        <template #title>
          <div>
            <span class="font-weight-bold text-primary"><b>Family Form</b></span>
          </div>
          <div
            v-if="isFilledFamilyForm && familySettings.is_required_confirmation_family_form && !isConfirmedFamilyForm"
            class="application-steps__tab-status"
          >{{ familyFormStatus ? familyFormStatus : 'In Review' }}</div>
          <div
            v-else-if="isFilledFamilyForm"
            class="application-steps__tab-status_approved"
          ></div>
        </template>

        <FamilyForm
          @nextStep="goNextTabIfAvailable"
        />
      </b-tab>
      <b-tab
        v-for="(step, index) in filteredStepsList"
        :key="index"
        title-item-class="application-steps__tab"
        title-link-class="application-steps__tab-link"
        :disabled="step.isDisabled || isDisableToFillByFamilyForm"
        @click="setTabIndexQuery"
      >
        <template #title>
          <div>
            <span class="font-weight-bold text-primary">{{ stepIndex(index) }} <b>{{ step.name }}</b></span>
          </div>
          <div
            v-if="step.status && step.status !== 'Approved'"
            class="application-steps__tab-status"
          >{{ step.status }}</div>
          <div
            v-if="step.status && step.status === 'Approved'"
            class="application-steps__tab-status_approved"
          ></div>
        </template>

        <ApplicationStepForm
          v-if="step.preparedFields && isDataFullPrepared"
          :step="step"
          :form-fields="step.preparedFields"
          @nextStep="goNextTabIfAvailable"
        />
      </b-tab>

    </b-tabs>

    <group-users-list-modal />
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

import FamilyForm from '@/views/Application/FamilyForm.vue'
import ApplicationStepForm from '@/views/Application/ApplicationStepForm.vue'
import store from '@/store'
import { mapGetters } from 'vuex'

import GroupUsersListModal from '@/components/site/group-users-list/GroupUsersListModal.vue'
import axios from '@axios'

export default {
  components: {
    FamilyForm,
    BTabs,
    BTab,

    ApplicationStepForm,
    GroupUsersListModal,
  },
  filters: {
    toFixedNuber(value) {
      return value.toFixed(2)
    }
  },
  data() {
    return {
      userId: this.$router.currentRoute.query.user_id || getUserData().id,
      familyId: getUserData().family_id,
      type: ['parent', 'child'].includes(getUserData().role) ? 'Camper' : 'Staff',
      isCollapsed: false,
      isFamilyFormCollapsed: true,
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'applicationRegistration/getApplicationId',
      programName: 'applicationRegistration/getProgramName',
      familySettings: 'applicationRegistration/getFamilySettings',
      isApplicationAccepted: 'applicationRegistration/getIsApplicationAccepted',
      filteredStepsList: 'applicationRegistration/getFilteredStepsList',
      paymentContractIsExist: 'applicationRegistration/getPaymentContractIsExist',
      isExistCrossProgramsConditions: 'applicationRegistration/getIsExistCrossProgramsConditions',
      crossProgramConditionalFieldsData: 'applicationRegistration/getCrossProgramConditionalFieldsData',
      getIsUseFamilyForm: 'applicationRegistration/getIsUseFamilyForm',
      isFilledFamilyForm: 'applicationRegistration/getIsFilledFamilyForm',
      isConfirmedFamilyForm: 'applicationRegistration/getIsConfirmedFamilyForm',
      familyFormStatus: 'applicationRegistration/getFamilyFormStatus',
    }),
    tabIndex: {
      get: function () {
        return store.getters['applicationRegistration/getCurrentTabIndex']
      },
      set: function (val) {
        store.commit('applicationRegistration/SET_TAB_INDEX', val)
      }
    },
    isDataFullPrepared: {
      get: function () {
        return store.getters['applicationRegistration/getIsDataFullPrepared']
      },
      set: function (val) {
        store.commit('applicationRegistration/SET_IS_DATA_FULL_PRAPARED', val)
      }
    },
    isDisableToFillByFamilyForm() {
      if (this.familySettings?.is_family_form_required_for_applications && !this.isFilledFamilyForm) {
        return true
      }
      return this.familySettings?.is_required_confirmation_family_form && !this.isConfirmedFamilyForm
    },
    isFamilyTabActive() {
      if (!this.getIsUseFamilyForm) {
        return false
      }
      if (!this.isFilledFamilyForm) {
        return true
      }
      if (!this.familySettings?.is_required_confirmation_family_form) {
        return false
      }
      return this.isConfirmedFamilyForm
    },
  },
  watch: {
    tabIndex() {
      if (this.applicationId) {
        this.setApplicationData(this.applicationId)
      }
    },
  },
  async created() {
    store.commit('applicationRegistration/RESET_STATE')
    store.commit('applicationRegistration/SET_TYPE', this.type)
    store.commit('applicationRegistration/SET_USER_ID', this.userId)

    store.commit('SET_LOADING_STATUS', true)

    const applicationId = this.$router.currentRoute.query.app_id
    if (applicationId) {
      store.commit('applicationRegistration/SET_APPLICATION_ID', applicationId)
      await this.setApplicationData()
    }

    const programID = this.$router.currentRoute.params.id
    if (programID) {
      await store.dispatch('applicationRegistration/fetchUserAttributes', {
        user_id: this.userId,
      })

      if (this.familyId) {
        await store.dispatch('applicationRegistration/fetchFamilyData', {
          family_id: this.familyId,
        })
      }

      await store.dispatch('programs/fetchProgramById', programID)
        .then(response => {
          store.commit('applicationRegistration/SET_PROGRAM', response)
          store.dispatch('applicationRegistration/prepareFamilyForm', {
            family_settings: response.family_settings,
            family_form: response.family_form,
          })
          store.commit('applicationRegistration/SET_STEP_STATUS')
          this.prepareData()
        })
    }

    if (!this.isFilledFamilyForm) {
      this.isFamilyFormCollapsed = false
    }
    store.commit('SET_LOADING_STATUS', false)

    if (applicationId) {
      await store.dispatch('alertMessage/fetchInfoMessages',{ application_id: applicationId })
    }
  },
  async beforeRouteEnter(to, from, next) {
    const role = getUserData().role
    const loggedUserId = getUserData().id
    const programId = to.params.id
    const response = await axios.get(`programs/${programId}`)
    const program = response.data.data
    next((vm) => {
      if (from.name !== 'archive-applications' && !program.is_active) {
        if (program.sharing_settings.type === 'only_selected_accounts') {
          const isAvaliableForThisUser = program.sharing_settings.selectedAccounts.some(item => item === loggedUserId)
          if (!isAvaliableForThisUser) {
            vm.$router.replace({name: "error-404"})
            return
          }
        }
      }

      if (role == 'parent' && from.name !== "application-select-child" && to.query.app_id == undefined) {
        vm.$router.replace({name: "application-select-child", params: {id: programId}})
      }
    });
  },
  methods: {
    async prepareData() {
      this.isDataFullPrepared = false
      await this.prepareCrossProgramsConditions()
      await this.prepareConditionsFromSteps()
      await this.setFormsData()
      await this.checkIfAllFieldsInStepAreHidden()
      await this.enableSteps()
      await this.selectActiveTab()
      await this.disableEditingStepsIfpaymentContractIsExist()
      await this.disableEditingStepsByEditingApplicationPermissions()
      this.isDataFullPrepared = true
    },
    async setFormsData() {
      store.commit('applicationRegistration/SET_FROMS_DATA')
      store.commit('applicationRegistration/SET_FORMS_VALUES_FROM_USER_ATTRIBUTES')
      store.commit('applicationRegistration/SET_FORMS_VALUES_FROM_APPLICATION_FIELDS')
      store.commit('applicationRegistration/CHECK_CONDITIONS')
    },
    async setApplicationData() {
      await store.dispatch('applications/fetchApplicationById', this.applicationId)
        .then(response => {
          if (!response.is_related_by_family_for_current_user && response.sharing_settings.type === 'only_selected_accounts') {
            const loggedUserId = getUserData().id
            const isAvaliableForThisUser = response.sharing_settings.selectedAccounts
              .some(item => item === loggedUserId)
            if (!isAvaliableForThisUser) {
              this.$router.push('error-404')
              return
            }
          }
          store.commit('applicationRegistration/SET_APPLICATION', response)
          if (response.payment_contract) {
            store.commit('applicationRegistration/SET_PAYMENT', response.payment_contract)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async selectActiveTab() {
      if (this.$router.currentRoute.query.tab_index) {
        this.$nextTick(() => {
          this.tabIndex = Number(this.$router.currentRoute.query.tab_index)
        })
      } else {
        const index = this.filteredStepsList.map(step => step.status).lastIndexOf("Approved")

        this.$nextTick(() => {
          if (this.isFamilyTabActive) {
            this.tabIndex = 0
          } else if (index == this.filteredStepsList.length - 1) {
            this.tabIndex = index
          } else {
            this.tabIndex = index + (this.getIsUseFamilyForm ? 2 : 1)
          }
        })
      }
    },
    setTabIndexQuery() {
      this.$nextTick(() => {
        const query = Object.assign({}, this.$route.query)
        if (query.tab_index == this.tabIndex) return
        query.tab_index = this.tabIndex
        this.$router.push({ name: 'application-create', query })
      })
    },
    async enableSteps() {
      for (let i = 0; i < this.filteredStepsList.length; i++) {
      //   this.filteredStepsList[i].isDisabled = true
      //
      //   if (i === 0) {
      //     this.filteredStepsList[i].isDisabled = false
      //   }
      //
      //   if (["Approved"].includes(this.filteredStepsList[i].status)) {
      //     this.filteredStepsList[i].isDisabled = false
      //     // if (i < this.filteredStepsList.length - 1) {
      //     //   this.filteredStepsList[i + 1].isDisabled = false
      //     // }
      //     continue
      //   }
      //
      //   if (["Rejected"].includes(this.filteredStepsList[i].status)) {
      //     this.filteredStepsList[i].isDisabled = false
      //     continue
      //   }
      //
      //   if (i > 1 && ["Required To Continue", "Requires Approval To Continue"].includes(this.filteredStepsList[i - 1].requirement_option) &&
      //     this.filteredStepsList[i - 1].status !== "Approved"
      //   ) {
      //     // this.filteredStepsList[i].isDisabled = false
      //     // continue
      //     break
      //   } else {
      //     this.filteredStepsList[i].isDisabled = false
      //
      //     if (["Required To Continue", "Requires Approval To Continue"].includes(this.filteredStepsList[i].requirement_option)) {
      //       break
      //     }
      //   }


        if (this.filteredStepsList[i].status == "Approved") {
          this.filteredStepsList[i].isDisabled = false
          if (i < this.filteredStepsList.length - 1) {
            this.filteredStepsList[i+1].isDisabled = false
          }
        } else if (this.filteredStepsList[i].requirement_option == "Required To Continue" 
         || this.filteredStepsList[i].requirement_option == "Requires Approval To Continue") {
          this.filteredStepsList[i].isDisabled = false
          break
        } else {
          this.filteredStepsList[i].isDisabled = false
        }

        if (this.isApplicationAccepted) {
          this.filteredStepsList[i].isDisabled = false
        }
      }
    },
    goNextTabIfAvailable() {
      this.enableSteps()
      this.$nextTick(() => {
        this.$store.commit('applicationRegistration/INCREMENT_TAB_INDEX')
      })
    },
    stepIndex(val) {
      const index = val + 1
      return index < 10 ? `0${index}` : index
    },
    async prepareCrossProgramsConditions() {
      if (this.isExistCrossProgramsConditions) {
        store.commit('applicationRegistration/PREPARE_CROSS_PROGRAMS_CONDITIONAL_FIELDS_DATA')
        await store.dispatch('applicationRegistration/fetchUserFieldResults', {
            user_id: this.userId,
            fields_data: this.crossProgramConditionalFieldsData,
          })
        store.commit('applicationRegistration/PREPARE_CROSS_PROGRAMS_CONDITIONS')
      }
    },
    async prepareConditionsFromSteps() {
      store.commit('applicationRegistration/PREPARE_CONDITIONS_FROM_STEPS')
    },
    async checkIfAllFieldsInStepAreHidden() {
      store.commit('applicationRegistration/CHECK_IF_ALL_FIELDS_IN_STEP_ARE_HIDDEN')
    },
    toggleCollapseTabs() {
      this.isCollapsed = !this.isCollapsed
    },
    toggleCollapseFamilyFormsTabs() {
      this.isFamilyFormCollapsed = !this.isFamilyFormCollapsed
    },
    disableEditingStepsIfpaymentContractIsExist() {
      this.$store.commit('applicationRegistration/DISABLE_EDITING_STEPS_IF_PAYMENT_CONTRACT_IS_SELECTED')
    }    ,
    disableEditingStepsByEditingApplicationPermissions() {
      this.$store.commit('applicationRegistration/SET_EDITABLE_SETTINGS_FOR_STEPS')
    }
  }
}
</script>

<style scoped>
.test {
  max-width: 350px;
  width: 100%;
  height: 80px;
  overflow-y: hidden;
  font-weight: normal;
  background: rgba(224, 180, 191, 0.35);
}
</style>