<template>
  <label
    class="nice-radio payment-option-radio"
  >
    <input
      type="radio"
      :value="option.id"
      name="payment-option"
      :disabled="disabled"
      :checked="option.id == selectedOption"
      @change="selectOption"
    />
    <div
      class="payment-option"
    >
      <div class="payment-option__vertical-line"></div>
      <h4 class="payment-option__title payment-option__item">{{ option.name ? option.name : 'Option ' + order }}</h4>
      <div class="payment-option__price payment-option__item">
        <PercentSVG />
        <span>Amount ${{ amount }}</span>
      </div>
<!--      <div-->
<!--        v-if="depositAmount"-->
<!--        class="payment-option__price payment-option__item"-->
<!--      >-->
<!--        <DollarSVG />-->
<!--        <span>Deposit ${{ depositAmount }}</span>-->
<!--      </div>-->
      <div class="payment-option__schedule">
        <template
          v-if="option.schedule_option.timeSchedule == 'Charge Immediately'"
        >
          <div class="payment-option__item">
            <FileSVG />
            <span>{{ option.schedule_option.timeSchedule }}</span>
          </div>
        </template>

        <template v-else>
          <div class="payment-option__item">
            <SplitSVG />
            <span>{{ option.schedule_option.timeSchedule }} {{ option.schedule_option.paymentAmountRange.from }}-{{ option.schedule_option.paymentAmountRange.to }}</span>
            <b-form-spinbutton
              id="range"
              v-model="option.amountOfPayments"
              :min="option.schedule_option.paymentAmountRange.from"
              :max="option.schedule_option.paymentAmountRange.to"
              inline
              :disabled="disabled"
              @input="checkInvalidOptionSettings"
            />
          </div>
          
          <div class="payment-option__item flex-nowrap">
            <CalendarSVG />
            <span>Start Date</span>
            <date-picker
              v-model="startDate"
              input-class="payment-option__start-date"
              valueType="MM/DD/YYYY"
              format="MM/DD/YYYY"
              placeholder="Select"
              :disabled="disabled"
              :disabled-date="disabledStartDates"
              @input="selectStartDate"
            />
          </div>
          <div class="payment-option__item">
            <TimeSVG />
            <span>Ending {{option.schedule_option.endDate}}</span>
          </div>
        </template>
      </div>
      <div class="payment-option__method payment-option__item">
        <DollarSVG />
        <span>Payment method</span>
        <v-select
          v-model="selectedMethod"
          class="payment-option__select"
          placeholder="Select"
          :options="option.method"
          :searchable="false"
          :clearable="false"
          :disabled="disabled"
          @input="selectMethod"
        />
      </div>
      <div
        v-if="isInvalidOptionSettings"
        class="payment-option__disclaimer"
      >
        *Payments must be complete by {{option.schedule_option.endDate}}. Please adjust the start date and amount of payments.
      </div>
    </div>

  </label>

  
</template>

<script>
import moment from 'moment'

import {
  BFormSpinbutton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import PercentSVG from '@/assets/images/icons/percent.svg'
import FileSVG from '@/assets/images/icons/file.svg'
import SplitSVG from '@/assets/images/icons/split.svg'
import CalendarSVG from '@/assets/images/icons/calendar.svg'
import TimeSVG from '@/assets/images/icons/time.svg'
import DollarSVG from '@/assets/images/icons/dollar.svg'

export default {
  components: {
    BFormSpinbutton,
    DatePicker,
    vSelect,

    PercentSVG,
    FileSVG,
    SplitSVG,
    CalendarSVG,
    TimeSVG,
    DollarSVG,
  },
  props: {
    selectedOption: {
      type: Number,
      default: 0,
    },
    option: {
      type: Object,
      default: () => {},
    },
    order: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    totalSum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedMethod: this.option.selectedMethod,
      startDate: this.option.startDate,
      isInvalidOptionSettings: false
    }
  },
  computed: {
    amount() {
      let amount = 0
      if (this.option.price_option.priceType == 'Percent discount') {
        amount = this.totalSum - this.totalSum * this.option.price_option.percentDiscount / 100
      } else if (this.option.price_option.priceType == 'Dollar discount') {
        amount = this.totalSum - this.option.price_option.dollarDiscount
      } else {
        amount = this.totalSum
      }
      return amount.toFixed(2)
    },
    depositAmount() {
      let amount = 0
      if (this.option.schedule_option.timeSchedule === 'Split over') {
        amount = Number(this.option.price_option.requiredDeposit)
      }

      return amount.toFixed(2)
    }
  },
  watch: {
    isInvalidOptionSettings(val) {
      this.optionValidation(val)
    },
  },
  methods: {
    selectOption() {
      this.$emit('selectOption', this.option)
      this.optionValidation(this.isInvalidOptionSettings)
    },
    checkInvalidOptionSettings() {
      if (this.option.schedule_option.timeSchedule === 'Split over') {
        const startDate = moment(this.option.startDate, 'MM/DD/YYYY').add(this.option.amountOfPayments - 1, 'M')
        const endDate = moment(this.option.schedule_option.endDate, 'MM/DD/YYYY')

        if (startDate > endDate) {
          this.isInvalidOptionSettings = true
          return
        }
      }

      this.isInvalidOptionSettings = false
    },
    optionValidation(val) {
      const data = {
        id: this.option.id,
        hasError: val,
      }
      this.$emit('optionValidation', data)
    },
    disabledStartDates(date) {
      const yesterday = Date.now() - 24 * 60 * 60 * 1000
      const endDate = new Date(this.option.schedule_option.endDate)

      const disabledPayDate = !this.option.available_days.includes(date.getDate())

      return yesterday > date || date > endDate || disabledPayDate
    },
    selectMethod(val) {
      this.option.selectedMethod = val
      if (this.option.id === this.selectedOption) {
        this.$emit('selectMethod', val)
      }
    },
    selectStartDate(val) {
      this.option.startDate = val
      this.checkInvalidOptionSettings()
    },
  }
}
</script>


