<template>
  <div class="credit__card">

    <p class="credit__card-text">
        <template v-if="applicationFee">A non refundable application fee of ${{applicationFee}} will be charged to your card. </template>
<!--        <template v-else>Your card will not be charged; however, a temporary hold will be placed on your card.</template> -->
        <!-- The remaining Tuition fee will be charged on application approval. -->
    </p>

    <div
      v-if="!hideCC"
      class="credit-card"
    >
      <h2 class="credit-card__title">Credit Card</h2>
      <div class="stripe-card__row stripe-card__row-two-col">
        <div class="stripe-card__number-row">
          <div
            :id="'card-number-' + stripeItemID"
            class="stripe-card__number"
          />
        </div>
        <div
          class="stripe-card__row-right-col"
        >
          <div
            :id="'card-expiry-' + stripeItemID"
            class="stripe-card__date"
          />
          <div
            :id="'card-cvc-' + stripeItemID"
            class="stripe-card__cvc"
          />
        </div>
      </div>
      <div
        :id="'payment-message-' + stripeItemID"
        v-if="hasCardError"
        class="form__error credit-card__error"
      >
        <div v-if="errorNumber">
          {{ errorNumber }}
        </div>
        <div v-if="errorCvc">
          {{ errorCvc }}
        </div>
        <div v-if="errorExpiry">
          {{ errorExpiry }}
        </div>
      </div>
      <div
        :id="'card-error-' + stripeItemID"
        class="form__error credit-card__error"
      />
    </div>

    <!-- <div class="card__fee"><span>Tuition Fee:</span> <span>$ {{totalSum || 0}}</span></div> -->
    <div class="payment__plan" v-if="false">
        <div class="form__row">
            <label class="center nice-checkbox">
                <input
                  v-model="paymentPlanActivated"
                  @change="$emit('activetePaymentPlan', paymentPlanActivated)"
                  type="checkbox" />
                <span>I Would like to make the Payment in monthly installments</span>
            </label>
        </div>
        <div class="row" v-if="paymentPlanActivated">
            <div class="col-6">
                <v-select
                    class="select"
                    placeholder="Amount of Payments"
                    :options="paymentMonthCountOptions"
                    label="amount"
                    :searchable="false"
                    v-model="paymentMonthCount"
                    @input="$emit('updateMonthCount', paymentMonthCount)"
                />
            </div>
            <div class="col-6">
                <h4 class="payment__plan-monthly">Monthly payment: ${{monthlyPayment}}</h4>
            </div>
      </div>
    </div>

    <label
      v-if="authSettings.use_terms_and_conditions"
      class="center nice-checkbox"
      :class="{'is-invalid': $v.terms.$dirty && !$v.terms.sameAs }"
    >
      <input
        v-model="terms"
        @change="$v.terms.$touch()"
        class="terms-and-conditions-link"
        type="checkbox"
      />
      <span></span>
      <a
        href="#"
        class="terms-and-conditions-link"
        @click="isShowModal = true"
      >
        I agree to terms & conditions
      </a>
    </label>

    <p class="form__error card__error">{{ errorTCMessage }}</p>

    <div class="credit-card__buttons">
      <span
        class="btn btn--color-outline-inactive credit-card__button"
        @click="$store.commit('applicationRegistration/DECREMENT_TAB_INDEX')"
      >Previous</span>
    <button
        class="btn btn--color-dark credit-card__button"
        @click="payout"
        :disabled="isBtnDisabled || hasCardError"
        :class="{ 'btn--loading': isLoading }"
    ><span v-if="!isLoading" v-html="submitButtonText"></span></button>
    </div>

    <TermsAndConditionalModal
      :is-show-modal="isShowModal"
      @closeModal="isShowModal = false"
    />

  </div>

</template>

<script>

import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'
import { sameAs } from 'vuelidate/lib/validators'

import TermsAndConditionalModal from '@/components/site/TermsAndConditionalModal.vue'
import { loadStripe } from '@stripe/stripe-js'
import uniqueId from 'lodash/uniqueId'
import {mapGetters} from "vuex";

  export default {
    components: {
      TermsAndConditionalModal,
      vSelect,
    },
    props: {
      applicationFee: {
        type: Number,
        default: 0,
      },
      totalSum: {
        type: Number,
        default: 0,
      },
      paymentPlan: {
        type: Object,
        default: () => {}
      },
      btnText: {
        type: String,
        default: '',
      },
      isBtnDisabled: {
        type: Boolean,
        default: false,
      },
      hideCC: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        errorNumber: null,
        errorExpiry: null,
        errorCvc: null,
        stripe: null,
        stripeItemID: '',

        paymentMonthCount: null,
        paymentPlanActivated: false,
        isLoading: false,
        errorTCMessage: '',
        terms: false,
        isShowModal: false,
      }
    },
    validations: {
      terms: {
        sameAs: sameAs( () => true )
      },
    },
    beforeMount() {
      this.stripeItemID = uniqueId()
    },
    async mounted() {
      // Load Stripe.js with your publishable key
      this.stripe = await loadStripe(this.publishableKey)
      const elements = this.stripe.elements()

      const style = {
        style: {
          base: {
            fontSize: '16px',
            color: process.env.SECONDARY_COLOR,
            backgroundColor: '#EDEDED',
            '::placeholder': {
              color: process.env.SECONDARY_COLOR,
            },
          },
        },
      }

      // Create and mount the card number input field
      this.cardNumber = elements.create('cardNumber', style)
      this.cardNumber.mount('#card-number-' + this.stripeItemID)
      this.cardNumber.addEventListener('change', (event) => {
        if (event.error) {
          this.errorNumber = event.error.message
        } else {
          this.errorNumber = null
        }
      })


      this.cardExpiry = elements.create('cardExpiry', style)
      this.cardExpiry.mount('#card-expiry-' + this.stripeItemID)
      this.cardExpiry.addEventListener('change', (event) => {
        if (event.error) {
          this.errorExpiry = event.error.message
        } else {
          this.errorExpiry = null
        }
      })

      this.cardCvc = elements.create('cardCvc', style)
      this.cardCvc.mount('#card-cvc-' + this.stripeItemID)
      this.cardCvc.addEventListener('change', (event) => {
        if (event.error) {
          this.errorCvc = event.error.message
        } else {
          this.errorCvc = null
        }
      })
    },
    computed: {
      ...mapGetters({
        publishableKey: 'paymentSettings/getStripePublishKey',
        authSettings: 'authSettings/getAuthSettings',
      }),
      hasCardError() {
        return !!this.errorNumber || !!this.errorExpiry || !!this.errorCvc
      },
      submitButtonText() {
        if (this.btnText) {
          return this.btnText
        }
        return 'Submit form, <br>continue to documents'
      },
      paymentMonthCountOptions() {
        const maxMonths = this.paymentPlan.max_months
        if(maxMonths){
          return Array.from({length: maxMonths}, (_, index) => index + 1)
        }
        return []
      },
      monthlyPayment() {
        if(this.paymentMonthCount){
          return (+this.totalSum/+this.paymentMonthCount).toFixed(2)
        }
        return +this.totalSum.toFixed(2);
      }
    },
    beforeDestroy () {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    },
    methods: {
      async createToken() {
        const { token, error } = await this.stripe.createToken(this.cardNumber)
        if (error) {
          // handle error here
          document.getElementById('#' + this.stripeItemID + 'card-error').innerHTML = error.message
          return
        }

        this.$emit('authCreditCard', token)
      },
      async payout() {
        this.errorTCMessage = ''

        if (this.$v.$invalid){
          await this.$v.$touch();

          if (this.authSettings.use_terms_and_conditions && this.$v.terms.$invalid){
            this.errorTCMessage = 'Please, agree to out terms and conditions'
            return
          }
        }

        if (this.hasCardError) {
          return
        }

        await this.createToken()
      }
    }
  };
</script>

<style lang="scss">
.payment__plan{
    margin-bottom: 30px;
    &-monthly{
        font-size: 20px;
        line-height: 26px;
        text-align: right;
        letter-spacing: -0.01em;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.is-invalid{
    color: #f00;
}
.col-6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
}
.terms-and-conditions-link {
  cursor: pointer;
  padding-left: 27px;
  color: #000;
}
</style>
